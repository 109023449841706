import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {FlexboxGrid, InputPicker} from "rsuite"
import {CurrencyInput, DateSelect, EntityPickerWithSelect, FormItem, TabSafeForm} from "../../form/rsuite-form"
import {AccountSelect} from "./form-elements"
import {
    changeAccount,
    changeAmount,
    changeBookDate,
    changeContraAccount,
    changeCostObject,
    changeReceiptDate,
    changeReceiptNumber,
    changeReference,
    changeSubject,
    changeTax,
    selectBooking,
    selectCanSimple,
    selectErrors,
    selectSimpleBooking,
    selectTaxChoices
} from "../store/editor-slice"
import {selectOrganization} from "../store/entries-slice";

export default function EntryEditorSimple() {

    useEffect(() => {
        document.getElementById('amount')?.focus()
    }, []);

    const dispatch = useDispatch()
    const canSimple = useSelector(selectCanSimple)
    const {amount, account, contraAccount, tax} = useSelector(selectSimpleBooking)
    const {bookDate, receiptDate, receiptNumber, subject, costObject, reference} = useSelector(selectBooking)
    const errors = useSelector(selectErrors)
    const organization = useSelector(selectOrganization)
    const taxChoices = useSelector(selectTaxChoices)

    if (!canSimple) {
        return <p>Eine Split-Buchung kann nicht als einfache Buchung bearbeitet werden.</p>
    }

    const formError = !errors ? undefined : {}

    if (errors) {
        for (const field of ["bookDate", "receiptDate", "receiptNumber", "tax", "subject", "costObject", "reference"]) {
            if (errors[field]?.errors) {
                formError[field] = errors[field].errors.join(" ");
            }
        }
    }

    if (formError) {
        if (errors.parts.children[0].children.account.errors) {
            formError.account = errors.parts.children[0].children.account.errors.join(" ");
        }
        if (errors.parts.children[1].children.account.errors) {
            formError.contraAccount = errors.parts.children[1].children.account.errors.join(" ");
        }
        if (errors.parts.children[0].children.dr.errors || errors.parts.children[1].children.cr.errors) {
            formError.amount = [].concat(errors.parts.children[0].children.dr.errors, errors.parts.children[1].children.cr.errors).join(" ");
        }
    }

    const formValue = {
        account,
        contraAccount,
        bookDate: bookDate == null ? null : new Date(bookDate),
        receiptDate: receiptDate == null ? null : new Date(receiptDate),
        receiptNumber,
        amount: !!amount ? {amount, currency: "EUR"} : null,
        tax,
        subject,
        costObject,
        reference
    };

    return (
        <TabSafeForm fluid formValue={formValue} formError={formError}>
            <FlexboxGrid>
                <FormItem
                    field="amount"
                    label="Umsatz"
                    accepter={CurrencyInput}
                    onChange={({amount}) => dispatch(changeAmount(amount))}
                />
                <FormItem
                    field="tax"
                    label="Steuer"
                    accepter={InputPicker}
                    data={taxChoices}
                    cleanable={false}
                    block
                    onChange={tax => dispatch(changeTax(tax))}
                />
                <FormItem
                    field="account"
                    label="Soll-Konto"
                    accepter={AccountSelect}
                    organization={organization}
                    onChange={account => dispatch(changeAccount(account))}
                />
                <FormItem
                    field="contraAccount"
                    label="Haben-Konto"
                    accepter={AccountSelect}
                    organization={organization}
                    onChange={account => dispatch(changeContraAccount(account))}
                />
                <FormItem
                    field="receiptNumber"
                    label="Belegnr."
                    onChange={receiptNumber => dispatch(changeReceiptNumber(receiptNumber))}
                />
                <FormItem
                    field="bookDate"
                    label="Leistungs-/Buchdatum" accepter={DateSelect} cleanable={false}
                    onChange={date => dispatch(changeBookDate(date))}
                />
                <FormItem
                    field="receiptDate"
                    label="Belegdatum" accepter={DateSelect} cleanable={false}
                    onChange={date => dispatch(changeReceiptDate(date))}
                />
                <FormItem
                    field="subject"
                    label="Buchungstext"
                    col={{xs: 24, sm: 16, md: 12, lg: 8}}
                    onChange={subject => dispatch(changeSubject(subject))}
                />
                <FormItem
                    field="costObject"
                    label="Kostenträger"
                    accepter={EntityPickerWithSelect}
                    modelId="accounting/cost-object"
                    col={{xs: 12, sm: 8, md: 6, lg: 4}}
                    onChange={costObject => dispatch(changeCostObject(costObject))}
                />
                <FormItem
                    field="reference"
                    label="Referenz"
                    accepter={EntityPickerWithSelect}
                    modelId="accounting/booking-reference"
                    col={{xs: 12, sm: 8, md: 6, lg: 4}}
                    onChange={reference => dispatch(changeReference(reference))}
                />
            </FlexboxGrid>
        </TabSafeForm>
    )
}
