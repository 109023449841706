import React from "react";
import {useSelector} from "react-redux";
import {Button, IconButton} from "rsuite";
import {selectAllParts, selectBooking} from "../store/editor-slice";
import {selectContext} from "../store/entries-slice";
import {useSaveAccountingBookingMutation} from "../store/accounting-api";

export function SaveButton({icon, children, ...props}) {
    const context = useSelector(selectContext)
    const [doSave, {isLoading}] = useSaveAccountingBookingMutation()
    const Element = icon ? IconButton : Button
    const parts = useSelector(selectAllParts)
    const {
        id, bookDate, receiptDate, receiptNumber, subject, tax,
        organization, costObject, reference, stack
    } = useSelector(selectBooking) ?? {}

    function save() {
        doSave({
            id,
            bookDate: bookDate + "T12:00:00Z",
            receiptDate: (receiptDate ?? bookDate) + "T12:00:00Z",
            receiptNumber,
            subject,
            organization,
            costObject,
            reference,
            parts: parts.map(({account: {id, modelId}, cr, dr}) => ({account: {id, modelId}, cr, dr})),
            stack,
            tax,
            book: "stack" !== context,
        })
    }

    return (
        <Element loading={isLoading} icon={icon} onClick={() => save()} {...props}>
            {children}
        </Element>
    )
}
